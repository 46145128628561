<template>
  <b-container
      class="bv-example-row mb-3"
      fluid
  >
    <hr>
    <b-row>
      <b-col lg="4" cols="12">
        <b-card class="mb-4">
          <b-row class="mt-50">
            <b-col
                lg="4"
                cols="12"
                class="cari-title"
            >
              <h4>Cari Detay</h4>
            </b-col>
            <b-col
                lg="8"
                cols="12"
                class="text-right pr-0"
            >
              <b-button
                  v-b-modal.cari-edit
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="bgInfo"
                  class="myButton mr-1 border-0"
                  :style="{backgroundColor:$store.getters.bgWarning}"
              >
                <feather-icon
                    icon="EditIcon"
                    class="mr-50"
                />
                Düzenle
              </b-button>
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="bgInfo"
                  class="myButton mr-1"
                  to="/cariListe"
              >
                <feather-icon
                    icon="ChevronLeftIcon"
                    class="mr-50"
                />
                Geri Dön
              </b-button>
            </b-col>
          </b-row>
          <hr>
          <b-list-group flush>
            <b-list-group-item
                class="font-weight-bold"
            >Firma Ünvanı: {{ cariDetayFaturaUnvan }}
            </b-list-group-item>
            <b-list-group-item
                class="font-weight-bold"
            >Yetkili: {{ cariDetayYetkili }}
            </b-list-group-item>
            <b-list-group-item
                class="font-weight-bold"
            >Telefon: {{ cariDetayTelefon }}
            </b-list-group-item>
            <b-list-group-item
                class="font-weight-bold"
            >E-Posta: {{ cariDetayPosta }}
            </b-list-group-item>
            <b-list-group-item
                class="font-weight-bold"
            >GSM: {{ cariDetayGSM }}
            </b-list-group-item>
            <b-list-group-item
                class="font-weight-bold"
            >Vergi Dairesi:
              <b-badge
                  v-show="cariDetayVergiDairesi"
                  variant="light-info"
              >
                {{ cariDetayVergiDairesi }}
              </b-badge>
            </b-list-group-item>
            <b-list-group-item
                class="font-weight-bold"
            >Vergi Numarası:
              <b-badge
                  v-show="cariDetayVergiNumarasi"
                  variant="light-success"
              >
                {{ cariDetayVergiNumarasi }}
              </b-badge>
            </b-list-group-item>
            <b-list-group-item
                class="font-weight-bold"
            >Adres: {{ cariDetayAdres }}
            </b-list-group-item>
          </b-list-group>
          <hr>
          <b-row class="mt-3">
            <b-col
                lg="6"
                class="d-flex justify-content-center"
            >
              <b-media no-body>
                <b-media-aside>
                  <b-avatar
                      rounded
                      size="42"
                      variant="light-success"
                  >
                    <feather-icon
                        size="18"
                        icon="CheckIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h4 class="transaction-title">
                    {{ cariDetayTahsilEdilecek }} TL
                  </h4>
                  <small>
                    <b-badge
                        pill
                        variant="light-danger"
                    >{{
                        cariDetayBakiye
                      }}
                    </b-badge>
                  </small>
                  <br>
                  <small>Güncel Bakiye</small>
                </b-media-body>
              </b-media>
            </b-col>
            <b-col
                lg="6"
                class="d-flex justify-content-center"
            >
              <b-media no-body>
                <b-media-aside>
                  <b-avatar
                      rounded
                      size="42"
                      variant="light-success"
                  >
                    <feather-icon
                        size="18"
                        icon="BriefcaseIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h4 class="transaction-title">
                    {{ cariDetayKayitliIslem }}
                  </h4>
                  <small>
                    <b-badge
                        pill
                        variant="light-primary"
                    >Kayıtlı İşlem
                    </b-badge>
                  </small>
                  <br>
                </b-media-body>
              </b-media>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col cols="12">
              <h4>Tahsilat</h4>
              <hr>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Tahsilat Yöntemi:">
                <v-select
                    v-model="girisTuru"
                    :components="{ OpenIndicator, Deselect }"
                    label="title"
                    :options="girisTuruOptions"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                  label="Fiş No:"
                  label-for="basicInput"
              >
                <b-form-input v-model="fisNo"/>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                  label="Tutar:"
                  label-for="basicInput"
              >
                <b-form-input
                    v-model="tutar"
                    step="0.0001"
                    type="number"
                    v-numericOnly

                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                  label="İşlem Notu:"
                  label-for="basicInput"
              >
                <b-form-textarea
                    v-model="aciklama"
                    rows="2"
                />
              </b-form-group>
            </b-col>
            <b-col
                cols="12"
                class="justify-content-end d-flex"
            >
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="bgInfo"
                  class="border-0"
                  @click="tahsilatSave"
                  :style="{backgroundColor:$store.getters.bgSuccess}"
              >
                Tahsilat Yap!
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col lg="8" cols="12">
        <b-card class="mb-4">
          <b-row class="mt-50">
            <b-col
                lg="4"
                md="6"
                sm="12"
                cols="12"
                class="cari-title"
            >
              <h4>Tahsilat ve Borç Kayıtları (Son 10 Kayıt)</h4>
            </b-col>
            <b-col
                lg="8"
                md="6"
                sm="12"
                cols="12"
                class="text-right pr-0"
                :class="width < 768 ? 'mt-50':''"
            >
              <b-button
                  v-b-modal.devir-girisi
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="bgInfo"
                  class="myButton mr-1 border-0"
                  :style="{backgroundColor:$store.getters.bgSuccess}"
              >
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                Devir Girişi
              </b-button>
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="bgSuccess"
                  class="myButton mr-1 border-0"
                  :to="'/cariEkstre/' + cariCardID"
                  :style="{backgroundColor:$store.getters.bgInfo}"
              >
                <feather-icon
                    icon="FileIcon"
                    class="mr-50"
                />
                Cari Ekstre
              </b-button>
            </b-col>
          </b-row>
          <hr>
          <app-timeline>
            <app-timeline-item
                v-for="(item, index) in cariDetay"
                :key="index"
                :variant="colorControl(item)"
            >
              <div
                  class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
              >
                <h6>
                  <b-badge :variant="titleControlMoneyColor(item)">
                    {{ titleControlMoney(item) }}
                  </b-badge>
                  {{ titleControl(item) }}
                </h6>
              </div>
              <b-row>
                <b-col cols="4">
                  {{ item.kayitTarih }}
                </b-col>
                <b-col
                    class="text-muted text-center"
                    cols="4"
                >{{
                    item.islemNotu
                  }}
                </b-col>
                <b-col
                    class="d-flex justify-content-end"
                    cols="4"
                >
                  <b-button
                      v-if="item.borc != ''"
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="flat-success"
                      size="sm"
                      @click="faturalandir(item)"
                  >
                    <feather-icon
                        icon="FileTextIcon"
                        class="mr-25"
                    />
                    Faturalandır
                  </b-button>
                  <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="flat-danger"
                      size="sm"
                      @click="tahsilatRemove(item.kasaID)"
                  >
                    <feather-icon
                        icon="TrashIcon"
                        class="mr-50"
                    />
                    Kaydı Sil
                  </b-button>
                </b-col>
              </b-row>
            </app-timeline-item>
          </app-timeline>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
        id="cari-edit"
        ok=""
        ok-only
        hide-footer
        centered
        size="lg"
        header-bg-variant="white"
        @hide="defValAta"
    >
      <b-row class="p-2">
        <b-col cols="12">
          <h3>
            <b-badge variant="primary">
              Cari Bilgileri
            </b-badge>
          </h3>
        </b-col>
        <b-col
            cols="6"
            class="mt-1"
        >
          <b-form-group
              label="İsim"
              label-for="basicInput"
          >
            <b-form-input
                id="basicInput"
                v-model="editCari.adi"
            />
          </b-form-group>
        </b-col>
        <b-col
            cols="6"
            class="mt-1"
        >
          <b-form-group
              label="Soyisim"
              label-for="basicInput"
          >
            <b-form-input
                id="basicInput"
                v-model="editCari.soyAdi"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
              label="Adres"
              label-for="basicInput"
          >
            <b-form-input
                id="basicInput"
                v-model="editCari.adres"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
              label="E-Posta"
              label-for="basicInput"
          >
            <b-form-input
                id="basicInput"
                v-model="editCari.ePosta"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
              label="GSM"
              label-for="basicInput"
          >
            <b-form-input
                id="basicInput"
                v-model="editCari.gsm"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
              label="Telefon"
              label-for="basicInput"
          >
            <b-form-input
                id="basicInput"
                v-model="editCari.telefon"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <h3>
            <b-badge variant="success">
              Fatura Bilgileri
            </b-badge>
          </h3>
        </b-col>
        <b-col cols="12">
          <b-form-group
              label="Firma Ünvanı"
              label-for="basicInput"
              class="mt-1"
          >
            <b-form-input
                id="basicInput"
                v-model="editCari.faturaUnvan"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
              label="Vergi No / TC NO"
              label-for="basicInput"
              class="mt-1"
          >
            <b-form-input
                id="basicInput"
                v-model="editCari.vergiNumarasi"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
              label="Vergi Dairesi"
              label-for="basicInput"
              class="mt-1"
          >
            <b-form-input
                id="basicInput"
                v-model="editCari.vergiDairesi"
                autocomplete="off"
            />
          </b-form-group>
        </b-col>
        <b-col
            cols="12"
            class="d-flex justify-content-end"
        >
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="bgInfo"
              class="border-0"
              @click="cariUpdate"
              :style="{backgroundColor:$store.getters.bgSuccess}"
          >
            Güncelle
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
        id="devir-girisi"
        ok=""
        ok-only
        hide-footer
        centered
        size="sm"
        header-bg-variant="white"
    >
      <b-row>
        <b-col cols="12">
          <h2 class="text-center">
            Devir Kaydı
          </h2>
        </b-col>
        <b-col cols="6">
          <b-form-group
              label="Tutar"
              label-for="basicInput"
          >
            <b-form-input
                id="basicInput"
                v-model="devir.tutar"
                step="0.0001"
                type="number"
                v-numericOnly
                autocomplete="off"
            />
          </b-form-group>
        </b-col>
        <b-col
            cols="6"
            class="mt-2"
        >
          <b-form-select
              v-model="devir.hareket"
              :options="devir.hareketOptions"
              value-field="key"
              text-field="title"
          >
            <template #first>
              <b-form-select-option :value="2">
                Cari Borcu
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col cols="12">
          <label for="textarea-default">İşlem Notu</label>
          <b-form-textarea
              id="textarea-default"
              v-model="devir.islemNotu"
              rows="3"
          />
        </b-col>
        <b-col
            cols="12"
            class="mt-1 d-flex justify-content-center"
        >
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="bgInfo"
              @click="devirSave"
          >
            Kaydet
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
        id="cari-fatura-olustur"
        ok-only
        ok-title="Git"
        centered
        header-bg-variant="white"
        size="lg"
        hide-footer
        @hide="hizliFaturaHide"
    >
      <b-row class="justify-content-center">
        <h2>Fatura Oluştur</h2>
        <p class="p-50">
          *Şahıs için TC Kimlik numarası 11 haneli, Tüzel firma Vergi Kimlik
          Numarası 10 haneli olmalıdır.
        </p>
      </b-row>
      <b-row class="justify-content-center p-1">
        <b-col lg="6" sm="12" md="12" cols="12">
          <b-form-group
              label="Fatura Ünvanı *Tüzel Firmalar İçin Zorunlu"
              label-for="basicInput"
          >
            <b-form-input
                id="basicInput"
                v-model="hizliFatura.faturaUnvan"
                autocomplete="off"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
              label="Faturanın Gideceği E-Posta"
              label-for="basicInput"
          >
            <b-form-input
                id="basicInput"
                v-model="hizliFatura.ePosta"
                autocomplete="off"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
              label="İsim * Şahıs için zorunlu"
              label-for="basicInput"
          >
            <b-form-input
                id="basicInput"
                v-model="hizliFatura.adi"
                autocomplete="off"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
              label="Soy İsim* Şahıs için zorunlu"
              label-for="basicInput"
          >
            <b-form-input
                id="basicInput"
                v-model="hizliFatura.soyAdi"
                autocomplete="off"
            />
          </b-form-group>
        </b-col>
        <b-col xl="3" lg="3" sm="6" cols="6">
          <b-form-group
              label="Vergi Dairesi"
              label-for="basicInput"
          >
            <b-form-input
                id="basicInput"
                v-model="hizliFatura.vergiDairesi"
                autocomplete="off"
            />
          </b-form-group>
        </b-col>
        <b-col xl="3" lg="3" sm="6" cols="6">
          <b-form-group
              label="*Vergi Numarasi"
              label-for="basicInput"
          >
            <b-form-input
                id="basicInput"
                v-model="hizliFatura.vergiNumarasi"
                autocomplete="off"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
              label="Fatura Adresi"
              label-for="basicInput"
          >
            <b-form-input
                id="basicInput"
                v-model="hizliFatura.faturaAdres"
                autocomplete="off"
            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
              label="Fatura İl"
              label-for="basicInput"
          >
            <b-form-input
                id="basicInput"
                v-model="hizliFatura.faturaIl"
                autocomplete="off"
            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
              label="Fatura İlce"
              label-for="basicInput"
          >
            <b-form-input
                id="basicInput"
                v-model="hizliFatura.faturaIlce"
                autocomplete="off"
            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
              label="Telefon"
              label-for="basicInput"
          >
            <b-form-input
                id="basicInput"
                v-model="hizliFatura.telefon"
                autocomplete="off"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <hr/>
        </b-col>
        <b-col lg="7" sm="12" md="12" cols="12">
          <b-form-group
              label="*Ürün Adı"
              label-for="basicInput"
          >
            <b-form-input
                id="basicInput"
                v-model="hizliFatura.urunAdi"
                autocomplete="off"
            />
          </b-form-group>
        </b-col>
        <b-col lg="2" sm="6" md="6" cols="6">
          <b-form-group
              label="*KDV Oranı"
              label-for="basicInput"
          >
            <b-form-input
                id="basicInput"
                v-model="hizliFatura.kdvOrani"
                autocomplete="off"
            />
          </b-form-group>
        </b-col>
        <b-col lg="3" sm="6" cols="6">
          <b-form-group
              label="*KDV Dahil Fiyat"
              label-for="basicInput"
          >
            <b-form-input
                id="basicInput"
                v-model="hizliFatura.toplamTutar"
                autocomplete="off"
                step="0.0001"
                type="number"
                v-numericOnly
            />
          </b-form-group>
        </b-col>
        <b-col lg="5" sm="12" md="12" cols="12">
          <b-form-group
              label="Fatura Notu"
              label-for="basicInput"
          >
            <b-form-input
                id="basicInput"
                v-model="hizliFatura.faturaNotu"
                autocomplete="off"
            />
          </b-form-group>
        </b-col>
        <b-col lg="3" sm="6" md="6" cols="6">
          <b-form-group
              label="Sipariş No/FişNo"
              label-for="basicInput"
          >
            <b-form-input
                id="basicInput"
                v-model="hizliFatura.siparisNo"
                autocomplete="off"
            />
          </b-form-group>
        </b-col>
        <b-col
            lg="4" sm="6" md="6" cols="6"
            class="align-center"
        >
          <b-form-checkbox
              v-model="hizliFatura.konaklamaVergisi"
              class="custom-control-success"
          >
            Konaklama Vergisi
          </b-form-checkbox>
        </b-col>
        <b-col
            cols="12"
            class="my-col-row justify-content-center"
        >
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :disabled="hizliFatura.sure"
              variant="bgInfo"
              class="border-0 mr-50"
              :style="{backgroundColor:$store.getters.bgSuccess}"
              @click="faturaOlustur"
          >
            <b-spinner v-if="hizliFatura.sure" small/>
            Fatura Oluştur
          </b-button>
          <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-dark"
              class="border-0 text-white"
              :style="{backgroundColor:$store.getters.bgDanger,border:'none!important'}"
              @click="hizliFaturaHide"
          >
            İptal
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </b-container>
</template>

<script>
import {
  BFormSelectOption,
  BListGroup,
  BListGroupItem,
  BMedia,
  BMediaAside,
  BMediaBody,
  BFormTextarea,
  BRow,
  BCol,
  BContainer,
  BTable,
  BBadge,
  BForm,
  BButton,
  BCard,
  BBreadcrumb,
  BButtonGroup,
  BFormInput,
  BInputGroup,
  BSpinner,
  BAvatar,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroupAppend,
  BFormCheckboxGroup,
  BFormCheckbox,
  BModal,
  VBModal,
} from 'bootstrap-vue'
import _ from 'lodash'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useWindowSize } from '@vueuse/core'

export default {
  components: {
    BFormSelectOption,
    AppTimeline,
    AppTimelineItem,
    BFormTextarea,
    vSelect,
    BMedia,
    BMediaAside,
    BMediaBody,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BContainer,
    BTable,
    BBadge,
    BForm,
    BButton,
    BCard,
    BBreadcrumb,
    BButtonGroup,
    BFormInput,
    BInputGroup,
    BSpinner,
    BAvatar,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroupAppend,
    BFormCheckboxGroup,
    BFormCheckbox,
    BModal,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      Deselect: {
        render: createElement => createElement('span', ''),
      },
      OpenIndicator: {
        render: createElement => createElement('span', ''),
      },
      devir: {
        tutar: '',
        hareket: 2,
        hareketOptions: [{
          title: 'Cari Alacağı',
          key: 1,
        }],
        islemNotu: '',
      },
      editCari: {
        adi: '',
        soyAdi: '',
        adres: '',
        ePosta: '',
        gsm: '',
        telefon: '',
        faturaUnvan: '',
        vergiNumarasi: '',
        vergiDairesi: '',
      },
      fisNo: '',
      aciklama: '',
      tutar: '',
      girisTuru: {
        title: 'Nakit',
        key: 1
      },
      girisTuruOptions: [
        {
          title: 'Nakit',
          key: 1
        },
        {
          title: 'Kredi Kartı',
          key: 2
        },
        {
          title: 'Havale',
          key: 3
        },
      ],
      id: this.$route.params.id,
      manuelID: null,
      hizliFatura: {
        sure: false,
        konaklamaVergisi: false,
        faturaUnvan: '',
        ePosta: '',
        adi: '',
        soyAdi: '',
        vergiDairesi: '',
        vergiNumarasi: '',
        faturaAdres: '',
        faturaIl: '',
        faturaIlce: '',
        urunAdi: '',
        kdvOrani: 10,
        toplamTutar: '',
        faturaNotu: '',
        siparisNo: '',
        telefon: '',
      },
    }
  },
  computed: {
    cariDetay() {
      return _.orderBy(
          this.$store.getters.cariDetayEkstre,
          'kayitTarih',
          'desc',
      )
    },
    salaryListItems() {
      return this.$store.getters.cariDetayListReturn
    },
    cariCardID() {
      if (this.$store.getters.cariDetayListReturn.cariData) {
        return this.$store.getters.cariDetayListReturn.cariData.carikartID
      }
    },
    cariDetayFaturaUnvan() {
      if (this.$store.getters.cariDetayListReturn.cariData) {
        return this.$store.getters.cariDetayListReturn.cariData.faturaUnvan
      }
    },
    cariDetayYetkili() {
      if (this.$store.getters.cariDetayListReturn.cariData) {
        return (
            this.$store.getters.cariDetayListReturn.cariData.adi
            + this.$store.getters.cariDetayListReturn.cariData.soyAdi
        )
      }
    },
    cariDetayTelefon() {
      if (this.$store.getters.cariDetayListReturn.cariData) {
        return this.$store.getters.cariDetayListReturn.cariData.telefon
      }
    },
    cariDetayPosta() {
      if (this.$store.getters.cariDetayListReturn.cariData) {
        return this.$store.getters.cariDetayListReturn.cariData.ePosta
      }
    },
    cariDetayGSM() {
      if (this.$store.getters.cariDetayListReturn.cariData) {
        return this.$store.getters.cariDetayListReturn.cariData.gsm
      }
    },
    cariDetayVergiDairesi() {
      if (this.$store.getters.cariDetayListReturn.cariData) {
        return this.$store.getters.cariDetayListReturn.cariData.vergiDairesi
      }
    },
    cariDetayVergiNumarasi() {
      if (this.$store.getters.cariDetayListReturn.cariData) {
        return this.$store.getters.cariDetayListReturn.cariData.vergiNumarasi
      }
    },
    cariDetayAdres() {
      if (this.$store.getters.cariDetayListReturn.cariData) {
        return this.$store.getters.cariDetayListReturn.cariData.adres
      }
    },
    cariDetayKayitliIslem() {
      if (this.$store.getters.cariDetayListReturn.hesapOzeti) {
        return this.$store.getters.cariDetayListReturn.hesapOzeti.length
      }
    },
    cariDetayTahsilEdilecek() {
      if (this.$store.getters.cariDetayListReturn.cariOzet) {
        return this.$store.getters.cariDetayListReturn.cariOzet.bakiye
      }
    },
    cariDetayBakiye() {
      if (this.$store.getters.cariDetayListReturn.cariOzet) {
        return this.$store.getters.cariDetayListReturn.cariOzet.bakiye == 0
            ? 'Bakiye Yok'
            : this.$store.getters.cariDetayListReturn.cariOzet.bakiye > 0
                ? 'Tahsil Edilecek'
                : this.$store.getters.cariDetayListReturn.cariOzet.bakiye < 0
                    ? 'Ödenecek'
                    : ''
      }
    },
  },
  created() {
    this.$store
        .dispatch('cariDetay', {
          vergiNumarasi: this.id,
        })
        .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
          const veri = res.data.cariData
          this.editCari.adi = veri.adi
          this.editCari.soyAdi = veri.soyAdi
          this.editCari.adres = veri.adres
          this.editCari.ePosta = veri.ePosta
          this.editCari.gsm = veri.gsm
          this.editCari.telefon = veri.telefon
          this.editCari.faturaUnvan = veri.faturaUnvan
          this.editCari.vergiNumarasi = veri.vergiNumarasi || veri.tcKimlik
          this.editCari.vergiDairesi = veri.vergiDairesi
          const mesaj = this.$store.getters.notificationSettings(res)
          if (mesaj) {
            this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                },
                { position },
            )
          }
        })
        .catch(err => {
          const mesaj = this.$store.getters.notificationSettings(err)
          if (mesaj) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          }
        })
  },
  beforeDestroy() {
    this.$store.commit('clearCariDetayList')
  },
  methods: {
    faturaOlustur() {
      this.hizliFatura.sure = true
      this.$store
          .dispatch('hizliFaturaOlustur', {
            faturaUnvan: this.hizliFatura.faturaUnvan,
            ePosta: this.hizliFatura.ePosta,
            adi: this.hizliFatura.adi,
            soyAdi: this.hizliFatura.soyAdi,
            vergiDairesi: this.hizliFatura.vergiDairesi,
            vergiNumarasi: this.hizliFatura.vergiNumarasi.replace(/\s/g, ''),
            faturaAdres: this.hizliFatura.faturaAdres,
            faturaIl: this.hizliFatura.faturaIl,
            faturaIlce: this.hizliFatura.faturaIlce,
            urunAdi: this.hizliFatura.urunAdi,
            siparisNo: this.hizliFatura.siparisNo,
            kdvOrani: this.hizliFatura.kdvOrani,
            toplamTutar: this.hizliFatura.toplamTutar,
            faturaNotu: this.hizliFatura.faturaNotu,
            telefon: this.hizliFatura.telefon,
            konaklamaVergisi: this.hizliFatura.konaklamaVergisi == true ? 1 : 0,
          })
          .then(res => {
            if (res.result) {
              if (res.result.status === 200) {
                this.hizliFaturaHide()
                this.$swal({
                  title: 'Başarılı',
                  text: this.$store.getters.notificationSettings(res) || ' Entegrator Web Servis Hatası, lütfen Entegrator Fatura Panelinden faturanızı kontrol ediniz !',
                  icon: 'success',
                  confirmButtonText: 'Kapat',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              } else {
                this.$swal({
                  title: 'Hata!',
                  text: this.$store.getters.notificationSettings(res) || ' Entegrator Web Servis Hatası, lütfen Entegrator Fatura Panelinden faturanızı kontrol ediniz !',
                  icon: 'error',
                  confirmButtonText: 'Kapat',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              }
            } else {
              this.$swal({
                title: 'Hata!',
                text: ' Entegrator Web Servis Hatası, lütfen Entegrator Fatura Panelinden faturanızı kontrol ediniz !',
                icon: 'error',
                confirmButtonText: 'Kapat',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            }
          })
          .catch(err => {
            this.$swal({
              title: 'Hata!',
              text: this.$store.getters.notificationSettings(err) || ' Entegrator Web Servis Hatası, lütfen Entegrator Fatura Panelinden faturanızı kontrol ediniz !',
              icon: 'error',
              confirmButtonText: 'Kapat',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          })
          .finally(() => {
            setTimeout(() => {
              this.hizliFatura.sure = false
            }, 3000)
          })
    },
    faturalandir(item) {
      this.hizliFatura.faturaUnvan = this.cariDetayFaturaUnvan
      this.hizliFatura.ePosta = this.cariDetayPosta
      this.hizliFatura.adi = this.$store.getters.cariDetayListReturn.cariData.adi
      this.hizliFatura.soyAdi = this.$store.getters.cariDetayListReturn.cariData.soyAdi
      this.hizliFatura.vergiDairesi = this.cariDetayVergiDairesi
      this.hizliFatura.vergiNumarasi = this.cariDetayVergiNumarasi
      this.hizliFatura.faturaAdres = this.cariDetayAdres
      this.hizliFatura.telefon = this.cariDetayTelefon
      this.hizliFatura.toplamTutar = item.borc
      this.hizliFatura.faturaNotu = item.islemNotu
      if (item.rezervasyonNo !== '0') {
        this.hizliFatura.urunAdi = 'Konaklama Hizmet Bedeli'
        this.hizliFatura.konaklamaVergisi = true
      }
      this.$bvModal.show('cari-fatura-olustur')
    },
    hizliFaturaHide() {
      this.$bvModal.hide('cari-fatura-olustur')
      this.hizliFatura.faturaUnvan = ''
      this.hizliFatura.ePosta = ''
      this.hizliFatura.adi = ''
      this.hizliFatura.soyAdi = ''
      this.hizliFatura.vergiDairesi = ''
      this.hizliFatura.vergiNumarasi = ''
      this.hizliFatura.faturaAdres = ''
      this.hizliFatura.faturaIl = ''
      this.hizliFatura.faturaIlce = ''
      this.hizliFatura.urunAdi = ''
      this.hizliFatura.kdvOrani = 10
      this.hizliFatura.toplamTutar = ''
      this.hizliFatura.faturaNotu = ''
      this.hizliFatura.konaklamaVergisi = false
    },
    defValAta() {
      this.$store
          .dispatch('cariDetay', {
            vergiNumarasi: this.id,
          })
          .then(res => {
            const veri = res.data.cariData
            this.editCari.adi = veri.adi
            this.editCari.soyAdi = veri.soyAdi
            this.editCari.adres = veri.adres
            this.editCari.ePosta = veri.ePosta
            this.editCari.gsm = veri.gsm
            this.editCari.telefon = veri.telefon
            this.editCari.faturaUnvan = veri.faturaUnvan
            this.editCari.vergiNumarasi = veri.vergiNumarasi || veri.tcKimlik
            this.editCari.vergiDairesi = veri.vergiDairesi
          })
    },
    devirSave() {
      this.devir.tutar = this.devir.tutar.replace(',', '.')
      this.$store
          .dispatch('devirSave', {
            vergiNumarasi: this.manuelID || this.id,
            id: this.salaryListItems.cariData.carikartID,
            tutar: this.devir.tutar,
            hareket: this.devir.hareket,
            islemNotu: this.devir.islemNotu,
          })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            if (res.result.status === 200) {
              this.$bvModal.hide('devir-girisi')
              this.devir.tutar = ''
              this.devir.islemNotu = ''
              this.devir.hareket = 2
              const mesaj = this.$store.getters.notificationSettings(res)
              this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Başarılı',
                      text: mesaj,
                      icon: 'ThumbsUpIcon',
                      variant: 'success',
                    },
                  },
                  { position },
              )
            } else {
              const mesaj = this.$store.getters.notificationSettings(res)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          })
    },
    cariUpdate() {
      this.$store
          .dispatch('cariUpdate', {
            carikartID: this.cariCardID,
            adi: this.editCari.adi,
            soyAdi: this.editCari.soyAdi,
            adres: this.editCari.adres,
            ePosta: this.editCari.ePosta,
            gsm: this.editCari.gsm,
            telefon: this.editCari.telefon,
            faturaUnvan: this.editCari.faturaUnvan,
            vergiNumarasi: this.editCari.vergiNumarasi,
            vergiDairesi: this.editCari.vergiDairesi,
          })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            if (res.result.status === 200) {
              this.$bvModal.hide('cari-edit')
              this.$store
                  .dispatch('cariDetay', {
                    vergiNumarasi:
                        res.data.cariData.vergiNumarasi || res.data.cariData.tcKimlik,
                  })
                  .then(response => {
                    const veri = response.data.cariData
                    this.manuelID = response.data.cariData.vergiNumarasi || response.data.cariData.tcKimlik
                    this.$router.push(
                        `/cariDetay/${veri.vergiNumarasi}` || veri.vergiNumarasi,
                    )
                    this.editCari.adi = veri.adi
                    this.editCari.soyAdi = veri.soyAdi
                    this.editCari.adres = veri.adres
                    this.editCari.ePosta = veri.ePosta
                    this.editCari.gsm = veri.gsm
                    this.editCari.telefon = veri.telefon
                    this.editCari.faturaUnvan = veri.faturaUnvan
                    this.editCari.vergiNumarasi = veri.vergiNumarasi || veri.tcKimlik
                    this.editCari.vergiDairesi = veri.vergiDairesi
                  })
              const mesaj = this.$store.getters.notificationSettings(res)
              this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Başarılı',
                      text: mesaj,
                      icon: 'ThumbsUpIcon',
                      variant: 'success',
                    },
                  },
                  { position },
              )
            } else {
              const mesaj = this.$store.getters.notificationSettings(res)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          })
    },
    tahsilatSave() {
      this.tutar = this.tutar.replace(',', '.')
      this.$store
          .dispatch('tahsilatSave', {
            vergiNumarasi: this.manuelID || this.id,
            id: this.salaryListItems.cariData.carikartID,
            girisTuru: this.girisTuru.key,
            tutar: this.tutar,
            fisNo: this.fisNo,
            aciklama: this.aciklama,
          })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            if (res.result.status === 200) {
              this.girisTuru = {
                title: 'Nakit',
                key: 1,
              }
              this.tutar = ''
              this.fisNo = ''
              this.aciklama = ''
              const mesaj = this.$store.getters.notificationSettings(res)
              this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: res.result.status === 200 ? 'Başarılı' : 'Hata!',
                      text: mesaj,
                      icon: 'ThumbsUpIcon',
                      variant: res.result.status === 200 ? 'success' : 'danger',
                    },
                  },
                  { position },
              )
            } else {
              const mesaj = this.$store.getters.notificationSettings(res)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          })
    },
    tahsilatRemove(kasaID) {
      this.$store
          .dispatch('tahsilatRemove', {
            kasaID,
            carikartID: this.cariCardID,
            vergiNumarasi: this.manuelID || this.id,
          })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            if (res.result.status === 200) {
              const mesaj = this.$store.getters.notificationSettings(res)
              this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Başarılı',
                      text: mesaj,
                      icon: 'ThumbsUpIcon',
                      variant: 'success',
                    },
                  },
                  { position },
              )
            } else {
              const mesaj = this.$store.getters.notificationSettings(res)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          })
    },
    titleControlMoneyColor(item) {
      if (item.islemTuru == 'Borç Girişi') {
        return 'danger'
      }
      return 'success'
    },
    titleControlMoney(item) {
      if (item.islemTuru == 'Borç Girişi') {
        return `${item.borc} TL `
      }
      return `${item.alacak} TL `
    },
    titleControl(item) {
      if (item.islemTuru == 'Borç Girişi') {
        return item.islemTuru
      }
      return item.islemTuru
    },
    colorControl(item) {
      return item.islemTuru == 'Borç Girişi' ? 'danger' : 'success'
    },
  },
  setup() {
    const {
      width,
    } = useWindowSize()
    return {
      width,
    }
  },

}
</script>

<style scoped>
.align-center {
  align-items: center !important;
  display: flex !important;
}

[dir] .badge[data-v-f95f1cd4] {
  padding: 0.3rem 0.4rem;
}

[dir] .badge {
  padding: 0.5rem 0.5rem;
}

[dir] h4 {
  margin-bottom: 0.1rem;
}

[dir] .list-group-item {
  padding: 0.45rem 0.25rem;
}

.table {
  text-align: center;
}

.spinners [dir] .badge[data-v-f95f1cd4] {
  padding: 0.3rem 0.4rem;
}

[dir] .badge {
  padding: 0.5rem 0.5rem;
}

[dir] h4 {
  margin-bottom: 0.1rem;
}

[dir] .list-group-item {
  padding: 0.45rem 0.25rem;
}

.table {
  text-align: center;
}

.spinners {
  position: absolute;
  margin-left: 47%;
  margin-top: 15%;
  width: 3rem;
  height: 3rem;
}

.mr-12 {
  margin-right: 12px !important;
  background-color: #2e83c9 !important;
}

.myHr {
  width: 60% !important;
}

[dir] .badge-primary {
  background-color: rgba(115, 103, 240, 0.12);
  color: #7367f0 !important;
}

.card-body {
  padding: 10px 15px !important;
}

.spinners {
  position: absolute;
  margin-left: 47%;
  margin-top: 15%;
  width: 3rem;
  height: 3rem;
}

.myButton {
  color: white !important;
}

.myButton:hover {
  color: white !important;
}

a {
  color: #408dcc !important;
}

a:hover {
  color: #6d62e4 !important;
}

.card-header {
  background-color: #2e83c9;
  height: 30px;
  position: relative;
}

.card-header h4 {
  position: absolute;
  color: white;
  margin-top: 10px;
}

.content-wrapper {

}

.card-body {
  padding: 10px 15px;
}

.row {
  justify-content: space-between;
}

.cari-title {
  align-items: center !important;
  display: flex !important;
}
</style>
<style>
.card-body {
  padding: 10px 15px;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
